/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  logo: require("./assets/images/SAredstar.png"),
  username: "Strike",
  title: "To Coventry Strikers",
  subTitle: emoji(
    `
    As you enter the fourth week of strike action, your strength, solidarity and determination to win this dispute has been an inspiration to workers in Coventry and across Britain.
    Trade Unionists everywhere have recognised the justness of your claim for a decent basic wage without having to work excessive overtime. 
    For that reason, messages of support and donations to the hardship fund have been flooding in.
    The winning of this strike will also inspire other workers to fight to end poverty wages.
    `
  ),
  resumeLink:
    "https://drive.google.com/file/d/1dMUOfg6aeSu2tW8nDxDGlFj5jRyjxd32/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  instagram: "https://instagram.com/covmarxists",
  facebook: "https://www.facebook.com/coventrymarxistsociety",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Poverty Wages",
  subTitle: `
  In 2020 the Office for National Statistics (ONS) said that the median wage was £31,461.

  The Joseph Rowntree Trust says that anyone earning less than 60% of this median after housing
  costs is in poverty. 60% of the median is £18,876 before paying rent or mortgage.

  Your wage scale from £22,183 to £27,741 after 12 years is before paying housing costs. 
  After paying rent or mortgage it is in the range of £13,507 to £19,065.
  `,
  skills: [
    'What does this mean?',
    'It means that you are working for poverty wages. And you are not alone.',
    'Coventry City Council claims that your pay is one of the best in the West Midlands.If that is the case, thousands of local authority workers in our region are also on poverty wages!',
    'These are poverty wages.'
  ],

  softwareSkills: [
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "What has been the City Council’s response to your claim?",
      logo: require("./assets/images/covLogo.png"),
      subHeader: "The Council is trying to defend what cannot be defended, the payment of poverty wages.",
      duration: "",
      descBullets: [
        "Lying about saying that you are already paid enough for the work that you do.",
        "Making empty thefts to devide by saying that if they concede your claim, other workers will demand equal pay too.",
        "False and illegal claims of saying that other workers will have to be sacked to pay for your claim.",
        "Hiding the money, saying that it does not have the money to pay you a wage above poverty wages.",
        "And now, to its shame, the City Council is contracting private waste firms to break your strike!"
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [

    {
      Stack: "Coventry Council Head of Waste & Fleet Mgmt £78,282", //Insert stack or technology you have experience in
      progressPercentage: "99%" //Insert relative proficiency in percentage
    },
    {
      Stack: "ONS Average Wage £31,461", //Insert stack or technology you have experience in
      progressPercentage: "40%" //Insert relative proficiency in percentage
    },
    {
      Stack: "HGV drivers wage after 12 years £27,741",
      progressPercentage: "35%"
    },
    {
      Stack: "HGV drivers entry wage £22,183",
      progressPercentage: "28%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: false, //Set it to true to show workExperiences Section
  experience: [

  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Why has the City Council taken this stand?",
  subtitle:
    `
    Since the banking crisis of 2008/9, every national government has cut public spending to reduce the government’s deficit caused by the bailout of the banks with public money. 
    Through cuts to jobs and services, wage freezes and attacks on terms and conditions, working class people have been made to pay for the banking crisis while the rich got even richer.  
    And more cuts are on the way.The pandemic has already cost £400bn and the Establishment will try to make us, working class people, pay for it.
    `
  ,
  projects: [
    // {
    //   image: require("./assets/images/saayaHealthLogo.webp"),
    //   projectName: "Saayahealth",
    //   projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //   footerLink: [
    //     {
    //       name: "Visit Website",
    //       url: "http://saayahealth.com/"
    //     }
    //     //  you can add extra buttons here.
    //   ]
    // },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Why are these facts important?"),
  subtitle:
    "",
  achievementsCards: [
    {
      title: "Council Failures",
      subtitle:
        "When the cuts started in 2010/11, Coventry City Council decided NOT to fight the cuts. ",
      // image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
      ]
    },
    {
      title: "Paying less",
      subtitle:
        "The end result today is that the City Council is trying to provide services and pay workers on 40% of the income it had in 2010.",
      // image: require("./assets/images/googleAssistantLogo.webp"),
      footerLink: [
      ]
    },

    {
      title: "Fight the cuts",
      subtitle: `
      When you don’t fight the cuts, you end up attacking your own workers through job cuts and paying poverty wages. You attack the very people who usually vote Labour.
      `,
      // image: require("./assets/images/pwaLogo.webp"),
      footerLink: [
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Where does Socialist Appeal stand in all of this?",
  subtitle:
    "We support your action 100%. It is a just strike!",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "",
      title: "Firstly, the strike has to be won. ",
      description:
        `
        The fight must be stepped up. 
        The Council is winning the propaganda war by delivering leaflets to every household in the city defending their stance. 
        `
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "What should the response be? ",
  subtitle: emoji(
    ""
  ),

  talks: [
    {
      title: "Public rally? ",
      subtitle: "Would a public rally in the city centre be useful where the public can be informed?",
      slides_url: "",
      event_url: ""
    },
    {
      title: "Open Letter to the Council?",
      subtitle: "Would an Open Letter to the Council, signed by prominent trade unionists across the city and the country, be helpful in putting pressure on the Council?",
      slides_url: "",
      event_url: ""
    },
    {
      title: "Appeal to trade unionists?",
      subtitle: "Would it be useful to appeal to other trade unionists, such as postal workers, not to cross the line? ",
      slides_url: "",
      event_url: ""
    },
    {
      title: "More leaftleting like this?",
      subtitle: "Would it be useful to leaflet other workplaces in the Council and elsewhere to get more support?",
      slides_url: "",
      event_url: ""
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Political fight"),
  subtitle: `Secondly, there is the political fight. 

  We live in a society that makes the rich richer and the poor poorer, where every crisis is paid for by us, working class people.

  This capitalist society cannot give people in Britain or in the world a decent standard of living, cannot abolish poverty wages, cannot build enough homes, cannot recruit enough nurses and doctors and cannot provide enough teachers for our children.

  That is why we are socialists. 

  Our aim is to transform society for the many and not the few, to create a society where the wealth that we produce is for the benefit of everyone and not the parasitic few at the top.

  While capitalism continues, every day will be a fight for a decent life, for decent wages.
  `,
  // Please Provide with Your Podcast embeded Link
  podcast: [
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Join us in this fight for a better society for all, a socialist society."),
  subtitle:
    "For more information, visit the following websites ",
  number: "https://www.socialist.net/refuse-workers-coventry.htm",
  email_address: "socialist.net"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
